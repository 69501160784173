.grid-container {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    justify-items: stretch;
    align-items: stretch;
 }

.sidebar {
     /* background-color:burlywood; */
    height: calc(100vh - 78px);

 }
.main {
    /* background-color:chocolate; */
    position: absolute;
    left: 0;
    width: 75%;
    height: calc(100vh - 78px);
}
.main-content {
    z-index: 100;
}
