body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


/* Navigations */

.navigation {
  width: 100%;
  background-color: #333;
  display: flex;
  padding: 20px;
  position: sticky;
  z-index: 11;
  top: 0px;
  margin: 0px;
  list-style-type: none;

}

.nav-item {
  /* margin-right: 10px; */
  list-style-type: none;
  display: flex;
}
.link {
  color: #fff;
  text-decoration: none;
}
.link:hover {
  color: #ddd;
  text-decoration: none;
}

.nav-search-bar {
  position: absolute;
  right: 20px;
  top: 0px;
}
.search-items {
  position: absolute;
  top: 50px;
}
.search-item {
  color: #fff;
  padding: 10px;
  padding-left: 10px;
  width: 13em;
  margin-bottom: 10px;
  background-color: #34495e;
}

.appBar {
  width: 100%;
  background-color: #2c3e50;
  color: #ecf0f1;
  height: 50px;
}
.appBarIcon {
  margin-top: 10px;
  margin-left: 10px;
  cursor: pointer;
  transition: all 500ms ease-in-out;
}

.appBarLoading{
  width: 25px;
  height: 25px;
  position: absolute;
  right: 25px;
  top: 25px;
}

.appBarIcon:hover {
  background-color: #34495e;
}

.check {
  padding: 10px;
}

.check_label { 
  padding: 10px;
}

/* Pages */

.page-container {
  /* padding-left: 20px; */
  width: 75%;
  /* padding: 20px; */
}
.sidebar {
  padding: 0px;
  position: absolute;
  right: 0px;
  top:77px;
  width: 25%;
  background-color: #333;
  height: calc(100% - 30px);
  overflow-y: scroll;
}

.sidebar-word {
  padding: 10px;
  /* color: #fff; */
}
.details {
  margin-left: 10px;
}

.sidebar-word-item {
  cursor: pointer;

}

.family-word-item {
  color: #333;
  font-weight: bold;
  font-size: 1rem;
  /* padding-left: 10px; */
  /* padding-top: 10px; */
  /* background-color: #2d3436; */
  /* border-bottom: 1px solid #000; */
  margin: 10px;
  cursor: pointer;
  transition: all 300ms ease-in-out; 
}
.family-word-item p {
  /* background-color: #ccc; */
  /* color: #2d3436; */
  width: 100%;
}


.family-word-item  p {
  margin: 10px;
}

.phonetik-p {
  font-weight: bold;
  font-size: 1.2rem;
}

.family {
  position: relative;
}

.family-not-found {
  background-image: url('https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/facebook/65/worried-face_1f61f.png');
  width: 200px;
  height: 170px;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.family-word-item-ratio {
  position: absolute;
  right: 10px;
  text-align: center;
  width: 25px;
  height: 25px;
  border-radius: 30px;
  font-size: 1rem;
  color: #fff;
  background-color: #2c3e50;
}

.family-not-found p {
  position: absolute;
  bottom: 0px;
}

.details-container {
  padding-top: 10px;
}

.details-in-nav {
  list-style-type: none;
  color: #fff;
}

.icon {
  float: right;
  clear: both;
  cursor: pointer;
}

.listItemIconRight {
  position: absolute;
  right: 10px;
}

.text-input-content {
  margin-top: 10px;
}


/* Inputs */
.text-input {
  /* border: 0px;
  padding: 5px;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  color: #333; */
}

.login-error {
  position: absolute;
  top: 0px;
  width: 100%;
  /* height: 25px; */
  padding: 10px;
  background-color: red;

  color: #fff;
  font-size: 1.2rem;
  text-align: center;
}

.show_added_family {
  width: 40px;
  height: 200px;
  min-height: fit-content;
  background-color: #333;
  position:absolute;
  bottom:100px;
  left: 0px;
  z-index: 10;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.show_added_family:hover {
  background-color: #2c3e50;
  /* width: 30px; */

}

.selected {
  color: yellow !important;
}


.sidebar-word a:visited {
  color: #34495e !important; 
}

.sidebar-word a {
  color: #ecf0f1;
  text-transform: none;
  text-decoration: none;
}


.sidebar-word a:hover {
  text-transform: none;
  text-decoration: none;
}

.dark {
  color: #2c3e50 !important;
}

.search-icon {
  padding: 20px;
}




#components-layout-demo-top-side .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}